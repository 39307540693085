<template>
  <div id='doctorList'>
    <!-- 标题 -->
    <div class="labelBox">
      <div class="title">管理员管理</div>
      <div>
        <el-button type="primary" size="medium" @click="add">添加管理员</el-button>
      </div>
    </div>

    <!-- 管理员列表 -->
    <el-table :data="list" style="width: 100%">
      <el-table-column prop="id" label="ID">
      </el-table-column>
      <el-table-column prop="account" label="登录账号">
      </el-table-column>
      <el-table-column prop="createTime" label="添加时间">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row.id)">编辑</el-button>
          <el-divider direction="vertical" v-if="aid != scope.row.id"></el-divider>
          <el-popconfirm title="确定删除吗？" @confirm="del(scope.row.id)" v-if="aid != scope.row.id">
            <el-button type="text" slot="reference" style="color:#F56C6C">删除</el-button>
          </el-popconfirm>

        </template>
      </el-table-column>
    </el-table>

    <!-- 添加文章弹窗 -->
    <el-dialog :title="editTitle" :visible.sync="editModal" width="50%">
      <div class="modalBox">
        <el-form :model="editForm" :rules="rules" ref="editForm" label-width="150px" class="demo-ruleForm">
          <el-form-item label="登录账号" prop="account">
            <el-input v-model="editForm.account"></el-input>
          </el-form-item>
          <el-form-item label="登录密码" prop="password">
            <el-input type="password" v-model="editForm.password"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editModal = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  var storage = window.localStorage;
  export default {
    name: 'adminList',
    data() {
      return {
        aid: '',
        id: '',
        list: [],
        editModal: false,
        editTitle: '',
        editForm: {
          account: '',
          password: '',
        },
        rules: {
          account: [{
            required: true,
            message: '请填写登录账号',
            trigger: 'blur'
          }],
          password: [{
            required: true,
            message: '请填写登录密码',
            trigger: 'blur'
          }],
        }
      }
    },

    created() {
      this.aid = storage.aid
      this.getList()
    },

    methods: {
      add() {
        this.editForm = {}
        this.id = ''
        this.editTitle = '添加管理员'
        this.editModal = true
      },

      edit(id) {
        this.id = id
        this.editTitle = '编辑管理员'
        this.$http.post('/admin/view', {
          id: this.id
        }).then(res => {
          if (res.data.code == 1) {
            this.editForm = res.data.data
            this.editModal = true
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },

      del(id) {
        this.$http.post('/admin/del', {
          id: id
        }).then(res => {
          if (res.data.code == 1) {
            this.getList()
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },

      submitForm() {
        this.$refs['editForm'].validate((valid) => {
          if (valid) {
            var data = {
              type: 2,
              account: this.editForm.account,
              password: this.editForm.password
            }
            if (this.id) {
              data.id = this.id
            }
            this.$http.post('/admin/edit', data).then(res => {
              if (res.data.code == 1) {
                this.editModal = false
                this.getList()
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

      getList() {
        this.$http.post('/admin/list', {}).then(res => {
          if (res.data.code == 1) {
            this.list = res.data.data
          }
        })
      }
    }
  }
</script>

<style scoped>
  #doctorList {
    padding: 30px;
    background: #fff;
  }

  .modalBox {
    padding-right: 100px;
    /* overflow:scroll; */
    overflow-y: auto
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: auto;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: auto;
    display: block;
  }

  .paginationBox {
    margin: 20px 0;
    text-align: right;
  }
</style>